import React, { useState } from "react";
import { DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useDispatch, useSelector } from "react-redux";
import { get_industry, industryReset } from "../../features/IndustrySlice";
import { FaEdit } from "react-icons/fa";
import dayjs from "dayjs";
import api from "../../services/api";
import PageHeader from "../../components/molecules/PageHeader";
import userPermission from "../../util/userPermission";
import { useNavigate } from "react-router-dom";
import QuotingModal from "./QuotingModal";
import { get_users_drd } from "../../features/usersSlice";
import { userPermissionNew } from "../../util/userPermissionNew";
import { setQuotingCount } from "../../features/authSlice";
import { Box, Button } from "@mui/material";
import {
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { FcOk } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import { getFullName } from "../../util/common";
function VirtualCardRequests() {
  const { usersDrd } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [record, setRecord] = useState([]);
  const [isQuoteModal, setIsQuoteModal] = useState(null);
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  function splitVirtualCardsInfo(data) {
    return data.flatMap((obj) => {
      const { virtual_cards_info, lead_num, ...rest } = obj;
      if (
        !Array.isArray(virtual_cards_info) ||
        virtual_cards_info.length === 0
      ) {
        return [{ ...obj, path: [lead_num] }];
      }
      const cardObjects = virtual_cards_info.map((card, index) => ({
        ...rest,
        virtual_cards_info: card,
        path: [lead_num, index + 1],
      }));
      return [{ ...obj, path: [lead_num] }, ...cardObjects];
    });
  }

  const getSERPReport = async (filter) => {
    setRecord([]);
    setIsLoading(true);
    const payload = {
      ...filter,
      filters: {
        ...filter?.filters,
        rules: [
          ...(filter?.filters?.rules || []),
          {
            field: "virtual_cards_info.requested_status",
            op: "eq",
            data: "PENDING",
          },
        ],
      },
    };
    if (!payload?.filters?.rules?.length) {
      delete filter.filters;
    }
    delete filter?.pageSize;
    try {
      const res = await api.post("api/leads/report", payload);
      if (res.status === 200) {
        const newObj = splitVirtualCardsInfo(res.data.data);
        setRecord({ data: newObj, totalItems: res.data.totalItems });
        dispatch(setQuotingCount(res?.data?.totalItems || 0));
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  React.useEffect(() => {
    dispatch(get_users_drd());
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const batchList = record?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });

  const handleConfirm = async (record) => {
    const c = window.confirm("Are you sure want to reject this request?");
    if (!c) return;
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/jobs/virtual_card_request_reject/${record?._id}/${record?.virtual_cards_info?._id}`
      );
      if (res.status === 200) {
        toast.success("Card request rejected successfully");
        reloadReport();
      } else {
        toast.error(res?.data?.message || "Card request couldn't be rejected");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(
        err?.response?.data?.message || "Card request couldn't be rejected"
      );
      console.log("🚀 ~ handleConfirm ~ err:", err);
    }
  };
  const columnDefs = [
    {
      field: "createdBy",
      headerName: "Created By",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) =>
        `${value.first_name || ""} ${value.last_name || ""}`,
      valueOptions: usersDrd,
      valueGetter: (params) => params.row?.createdBy,
      valueFormatter: (params) => params.value?.createdBy,
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bol">{params?.row?.createdBy}</span>
        ) : (
          <span className="font-bol">
            {getFullName(
              usersDrd?.find(
                ({ _id }) =>
                  params?.row?.virtual_cards_info?.requested_by === _id
              )
            )}
          </span>
        ),
    },
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bol">{params?.row?.name}</span>
        ) : (
          <span className="font-bol">
            {params?.row?.virtual_cards_info?.card_info?.name || ""}
          </span>
        ),
    },
    {
      headerName: "Phone",
      field: "phone",
      flex: 1,
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bol">{params?.row?.phone}</span>
        ) : null,
    },
    {
      headerName: "Service Cost",
      field: "service_cost",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.service_cost?.toFixed(2)}
          </span>
        ) : (
          <span>-</span>
        ),
    },
    {
      headerName: "Part Charge",
      field: "parts_charge",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <span className="font-bold">
            {params.row.parts_charge?.toFixed(2)}
          </span>
        ) : (
          <span>{params.row.parts_charge?.toFixed(2)}</span>
        ),
    },
    {
      headerName: "Tech Share",
      field: "tech_fee",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) => {
        return params.rowNode.type === "group" ? (
          <span className="font-bold">{params.row.tech_fee?.toFixed(2)}</span>
        ) : (
          <span>
            {params.row.virtual_cards_info?.requested_amount?.toFixed(2)}
          </span>
        );
      },
    },
    {
      headerName: "Status",
      field: "job_created",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value?.label,
      valueOptions: [
        { value: false, label: "Job not created" },
        { value: true, label: "Job created" },
      ],
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        job_created: value?.value,
      }),
      valueFormatter: (params) => params.value?.job_created,
      renderCell: (params) =>
        params.rowNode.type === "group" ? (
          <div>
            {`${params.row.job_created}${params.row.job_number ? " | " : ""}`}
            {params.row.job_number ? (
              <a
                href={`/jobs/update/preview/${params.row._id}`}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:underline cursor-pointer"
              >
                {`${params.row.job_number}`}
              </a>
            ) : null}
          </div>
        ) : (
          <span>{params.row.virtual_cards_info?.requested_status}</span>
        ),
    },
    {
      headerName: "Response by",
      field: "action_by",
      flex: 1,
      renderCell: (params) =>
        params.rowNode.type !== "group" ? (
          <span className="font-bol">
            {getFullName(
              usersDrd?.find(
                ({ _id }) => params?.row?.virtual_cards_info?.action_by === _id
              )
            )}
          </span>
        ) : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      renderCell: (params) => (
        <div>
          {userPermission("Update Job") && params.rowNode.type === "group" ? (
            <FaEdit
              onClick={() => {
                navigate(`/jobs/update/${params.row._id}`);
              }}
              className="my_navIcon"
              title="Update Job"
            />
          ) : null}
          {userPermissionNew("Card Request Report") &&
          params.row.virtual_cards_info.requested_status === "PENDING" ? (
            <>
              <FcOk
                onClick={() => {
                  setIsQuoteModal(params.row.records);
                }}
                size={22}
                className="my_navIcon"
                title="Accept Card Request"
              />
              <MdCancel
                onClick={() => {
                  handleConfirm(params.row.records);
                }}
                size={22}
                color="red"
                className="my_navIcon text-red-600"
                title="Reject Card Request"
              />
            </>
          ) : null}
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getSERPReport({
      ...sortingModel,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const reloadReport = () => {
    getSERPReport({
      ...paginationModel,
      ...sortingModel,
      size: paginationModel.pageSize,
    });
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    getSERPReport({
      page: 1,
      size: paginationModel.pageSize,
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
  };

  const getTreeDataPath = (row) => row.path;
  const groupingColDef = {
    headerName: "Lead #",
    flex: 1,
    minWidth: 150,
  };
  return (
    <>
      <PageHeader
        route="Setting > Industries"
        // heading="Quotes"
      />
      {isQuoteModal ? (
        <QuotingModal
          onClose={() => setIsQuoteModal(null)}
          jobDetail={isQuoteModal}
          reloadReport={reloadReport}
        />
      ) : null}
      {isLoading ? <DotsLoader /> : null}

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={batchList?.map((record, index) => {
            const {
              _id,
              lead_num,
              job_number,
              createdBy,
              name,
              phone,
              is_declined,
              createdAt,
              job_created,
              recording,
              path,
              service_cost,
              virtual_cards_info,
              parts_charge,
              tech_fee,
            } = record;
            return {
              _id,
              counter: index + 1,
              records: record,
              lead_num,
              createdBy: createdBy?.username,
              name,
              path,
              phone,
              is_declined,
              createdAt: dayjs(createdAt).format("ddd, MMM D, YYYY h:mm A"),
              recording,
              job_created: `${
                is_declined ? "Declined" : job_created ? "Job Created" : "Open"
              }`,
              job_number,
              service_cost,
              virtual_cards_info,
              parts_charge,
              tech_fee,
            };
          })}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          totalItems={record?.totalItems}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          treeData={true}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
        />
      </div>
    </>
  );
}

export default VirtualCardRequests;
