import { sideMenu } from "./menu.config";
import { FaExchangeAlt, FaPowerOff } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { logoutUser, logout } from "../../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { setStatus, setTimer } from "../../../features/dialerSlice";
import Dropdown from "./Dropdown";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { logo_towingwiz, logo_logical } from "../../../images";
import { sendIframeMsg } from "../../../util/iFrame";
import { toast } from "react-toastify";
import socket from "../SMS/socket";
const Sidebar2 = ({
  setIsSwitchOffice,
  show_navlabel,
  setLogingOut,
  isDroping,
  isDroped,
  isDropingOut,
  isDialer,
  show_nav,
}) => {
  const hostname = window.location.hostname === "towingwiz.com";
  const { status, isJobDone } = useSelector((state) => state.dialer);
  const { quotingCount } = useSelector((state) => state.auth);
  const { conversation } = useSelector((state) => state?.siteConversation);
  const user = JSON.parse(localStorage.getItem("user"));
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const logoutHandler = async () => {
    setLogingOut(true);
    await dispatch(logout());
    const eventName =
      user?.company_name?.toLowerCase().replaceAll(" ", "_") + "_message_list";
    socket.off(eventName);
    socket.off("message_list");
    socket.off("connect");
    socket.disconnect();
    socket.close();
    sendIframeMsg({
      action: "logout",
      user: user?.dialer_data?.dialer_user,
      pass: user?.dialer_data?.dialer_pass,
    });
    dispatch(logoutUser());
    dispatch(setStatus(""));
    dispatch(setTimer(""));
    setLogingOut(false);
  };
  const showError = () => {
    if (status === "INCALL" || status === "DEAD") {
      toast.info("Please hangup your current call before you proceed", {
        position: "top-center",
      });
    } else if (status === "DISPO") {
      toast.error("Please select a disposition...", {
        position: "top-center",
      });
    }
  };
  // const isAllowOld = (item) => {
  //   if (user?.tasks?.length > 0 && user?.role) {
  //     if (user?.tasks?.some((obj) => obj?.name === item?.task)) {
  //       return true;
  //     }
  //     return false;
  //   }
  // };
  const isAllow = (item) => {
    if (user?.menu_permissions?.length > 0 && user?.role) {
      if (user?.menu_permissions?.some((obj) => obj === item?.label)) {
        return true;
      }
      return false;
    }
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");
  React.useEffect(() => {
    let route = location.pathname.split("/")[1];
    setClickedOn(`/${route}`);
  }, [location.pathname]);
  const disbale =
    status && status !== "PAUSE" && status !== "WAIT" && !isJobDone;

  return (
    <div className={``}>
      <div
        className={`${
          !isDroping || isDropingOut
            ? "bg-[#f7f7f7] "
            : "bg-[#d5d5d5] shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-md"
        } fixed  w-[235px] top-0 bottom-0 border-r-2 border-[#e8e9eb] z-[99] !overflow-y-hidden transition-all`}
      >
        <div className="p-3.7 pb-1 border-b border-[#d5d5d5] !overflow-y-hidden">
          <Link to="/">
            <img
              src={hostname ? logo_towingwiz : logo_logical}
              alt="Callrings CRM"
            />
          </Link>
          {!user?.vendor_type && (
            <div className="!mt-2 text-center flex flex-col w-full justify-center">
              <small>{`${user?.company_name}`}</small>
            </div>
          )}
        </div>

        <div
          className={`${
            isDroped && isDialer === "flex"
              ? "h-[calc(100vh-450px)]"
              : "h-[calc(100vh-150px)]"
          } overflow-y-auto scrollbar`}
        >
          {sideMenu.map((item, key) => {
            const { label, to, Icon, children, onClick } = item;
            return isAllow(item) ? (
              <div className="w-full" key={key}>
                {item?.children ? (
                  <>
                    <div
                      className="transition-all page_link group hover:text-[#828282] hover:no-underline text-[#828282] transition flex items-center justify-between py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
                      onClick={() => {
                        setClickedOn(clickedOn === to ? "" : to);
                        setIsOpen(!isOpen);
                      }}
                    >
                      <div className="flex items-center">
                        <Icon className="icons group-hover:text-primary-100" />
                        {show_navlabel && (
                          <span className="!ml-1">{label}</span>
                        )}
                      </div>
                      <FaAngleDown />
                    </div>
                    {clickedOn === to && !isDroping && !disbale && (
                      <Dropdown
                        show_navlabel={show_navlabel}
                        label={label}
                        to={to}
                        Icon={Icon}
                        options={children}
                        key={key}
                        isDroping={isDroping}
                      />
                    )}
                  </>
                ) : label === "Jobs" &&
                  (isAllow({ label: "Progress" }) ||
                    isAllow({ label: "Card Request Report" })) ? (
                  <div className="flex items-center text-[#828282]">
                    <NavLink
                      title={label}
                      to={disbale ? location?.pathname : to}
                      onClick={disbale ? showError : onClick}
                      className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !pl-5 pr-1 w-fit outline-none border-0 cursor-pointer transition-all"
                    >
                      <Icon className="icons group-hover:text-primary-100" />

                      {show_navlabel && <span className="ml-1">{label}</span>}
                    </NavLink>
                    {isAllow({ label: "Progress" }) ? (
                      <>
                        {"|"}
                        <NavLink
                          title={"Progress"}
                          to={"/reports/job_progress_report"}
                          onClick={disbale ? showError : onClick}
                          className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !pl-1 pr-2 w-full outline-none border-0 cursor-pointer transition-all"
                        >
                          {/* <GiProgression className="icons group-hover:text-primary-100" /> */}
                          {show_navlabel && <span className="">Progress</span>}
                        </NavLink>
                      </>
                    ) : null}
                    {isAllow({ label: "Card Request Report" }) ? (
                      <>
                        {"|"}
                        <NavLink
                          title={"Card Request Report"}
                          to={"/virtual_card_requests"}
                          onClick={disbale ? showError : onClick}
                          className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !pl-1 pr-2 w-full outline-none border-0 cursor-pointer transition-all"
                        >
                          {/* <GiProgression className="icons group-hover:text-primary-100" /> */}

                          {show_navlabel && <span className="">Cards</span>}
                        </NavLink>
                      </>
                    ) : null}
                  </div>
                ) : label === "Lead" &&
                  isAllow({ label: "Quoting Request" }) ? (
                  <div className="flex items-center text-[#828282]">
                    <NavLink
                      title={label}
                      to={disbale ? location?.pathname : to}
                      onClick={disbale ? showError : onClick}
                      className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !pl-5 pr-1 w-fit outline-none border-0 cursor-pointer transition-all"
                    >
                      <Icon className="icons group-hover:text-primary-100" />

                      {show_navlabel && <span className="ml-1">{label}</span>}
                    </NavLink>
                    {"|"}
                    <NavLink
                      title={`Quoting`}
                      to={"/quoting_request"}
                      onClick={disbale ? showError : onClick}
                      className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !pl-1 pr-2 w-full outline-none border-0 cursor-pointer transition-all"
                    >
                      {show_navlabel && (
                        <span className="">{`Quoting (${
                          quotingCount || 0
                        })`}</span>
                      )}
                    </NavLink>
                  </div>
                ) : label !== "Progress" && label !== "Quoting" ? (
                  <NavLink
                    title={label}
                    to={disbale ? location?.pathname : to}
                    onClick={disbale ? showError : onClick}
                    className="page_link group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
                  >
                    <Icon className="icons group-hover:text-primary-100" />

                    {show_navlabel && (
                      <span className="ml-1">
                        {label === "Conversations"
                          ? `${label} (${conversation?.totalItems || 0})`
                          : label}
                      </span>
                    )}
                  </NavLink>
                ) : null}
              </div>
            ) : null;
          })}
          {isAllow({ label: "Switch Office", task: "Switch Office" }) && (
            <NavLink
              to
              className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] px-3 w-full outline-none border-0 cursor-pointer transition-all"
              onClick={() => (disbale ? showError : setIsSwitchOffice(true))}
            >
              <FaExchangeAlt className="group-hover:text-primary-100" />
              {show_navlabel && <span className="ml-1">Switch Office</span>}
            </NavLink>
          )}
          <NavLink
            to="/user_profile"
            className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
          >
            <FaUserGear className="group-hover:text-primary-100" />
            {show_navlabel && <span className="ml-1">User Profile</span>}
          </NavLink>
          <NavLink
            to
            onClick={disbale ? showError : logoutHandler}
            className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
          >
            <FaPowerOff className="group-hover:text-primary-100" />
            {show_navlabel && <span className="ml-1">Logout</span>}
          </NavLink>
          {/* {
          user?.role==="SUPER ADMINISTRATOR"?
          <NavLink
          to
          onClick={disbale?showError:receiveMsg}
          className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
        >
          <FaPowerOff className="group-hover:text-primary-100" />
          {show_navlabel && <span className="ml-1">Receive Msg</span>}
        </NavLink>:null
        }
        {
          user?.role==="SUPER ADMINISTRATOR"?
          <NavLink
          to
          onClick={disbale?showError:receiveGroupMsg}
          className="group hover:text-[#828282] hover:no-underline no-underline text-[#828282] flex items-center py-[3px] !px-5 w-full outline-none border-0 cursor-pointer transition-all"
        >
          <FaPowerOff className="group-hover:text-primary-100" />
          {show_navlabel && <span className="ml-1">Receive Group Msg</span>}
        </NavLink>:null
        } */}
        </div>
        {isDroping && !isDropingOut && (
          <div className="bg-[#f7f7f7] h-[300px] w-[210px] absolute bottom-1 ml-1 shadow-[0_0.125rem_0.5rem_rgb(0_0_0_/30%),_0_0.0625rem_0.125rem_rgb(0_0_0_/20%)] rounded-md"></div>
        )}
        <div className="flex justify-center text-center">
          <small className="text-center">Beta Version 0.5.5</small>
        </div>
      </div>
    </div>
  );
};

export default Sidebar2;
