import React from "react";
import { address_icn } from "../../../../images";
import CustomSelect from "../../Components/Selectors";
import { DotsLoader } from "../../../../components";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { BiRefresh } from "react-icons/bi";
import { FaLocationArrow } from "react-icons/fa";
function SingleFormLocation({ formik, errors }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [accuracy, setAccuracy] = React.useState(null);
  const [manualAddress, setManualAddress] = React.useState(true);
  const [autocomplete, setAutocomplete] = React.useState();
  const libraries = ["places", "drawing", "geometry"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries,
  });
  const onLoadPickup = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onChangeAddress = (prefix) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();

      formik?.setFieldValue(
        `complete_${prefix}_address`,
        place?.formatted_address
      );
      formik?.setFieldValue(`${prefix}_latitude`, lat);
      formik?.setFieldValue(`${prefix}_longitude`, lng);

      extractAddress(place, prefix);
    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };

  const extractAddress = (place, prefix) => {
    if (!Array.isArray(place?.address_components)) {
      return null;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("postal_code")) {
        formik.setFieldValue(`${prefix}_zip`, value?.long_name);
      }

      if (types.includes("locality")) {
        formik.setFieldValue(`${prefix}_city`, value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue(`${prefix}_state`, value?.short_name);
      }

      if (types.includes("country")) {
        formik.setFieldValue(`${prefix}_country`, value?.long_name);
      }
    });
  };
  const getLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition((position) => {
      formik.setFieldValue("pickup_latitude", position?.coords?.latitude);
      formik.setFieldValue("pickup_longitude", position?.coords?.longitude);
      setAccuracy(position?.coords?.accuracy);
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position?.coords?.latitude},${position?.coords?.longitude}&sensor=true&key=AIzaSyC6mZv20ylD8_kQqlryy9_SvWqNyyRPn64`
      )
        .then((res) => res.json())
        .then((json) => {
          setIsLoading(false);
          extractAddress(json.results[0], "pickup");
          formik.setFieldValue(
            "complete_pickup_address",
            json.results[0].formatted_address
          );
        });
    });
  };
  return (
    <div>
      {isLoading && <DotsLoader />}
      <div>
        {manualAddress ? (
          <div className="text-sm font-pop text-left font-medium bottom-3.7">
            <strong className="text-red-600">Note: </strong>
            {`Please manually enter the address in the fields below. Want to use `}
            <strong>Autocomplete</strong>?{" "}
            <span
              className="text-[#007bff] underline cursor-pointer"
              onClick={() => setManualAddress(!manualAddress)}
            >
              click here
            </span>
            {` to switch`}
          </div>
        ) : (
          <div className="text-sm font-pop text-left font-medium bottom-3.7">
            <strong className="text-red-600">Note: </strong>
            {`Start typing to search for your address or prefer manual entry? `}
            <span
              className="text-[#007bff] underline cursor-pointer"
              onClick={() => setManualAddress(!manualAddress)}
            >
              click here
            </span>
            {` to enter your address manually`}
          </div>
        )}
        {manualAddress ? (
          <div className="w-[95%]">
            <CustomSelect
              icon={address_icn}
              value={formik?.values?.complete_pickup_address}
              placeholder="Job Address"
              isInput
              name="complete_pickup_address"
              formik={formik}
              label={
                accuracy ? `Accurate to ${accuracy?.toFixed(2)} meters` : ""
              }
              errors={errors}
            />
          </div>
        ) : (
          <div>
            {!formik.values?.complete_pickup_address && isLoaded && (
              <div className="flex gap-4">
                <div className="w-full">
                  <Autocomplete
                    onPlaceChanged={() => onChangeAddress("pickup")}
                    onLoad={(autocomplete) => onLoadPickup(autocomplete)}
                  >
                    <CustomSelect
                      icon={address_icn}
                      placeholder="Job Address"
                      isInput
                      formik={formik}
                      errors={errors}
                      name={"job_address"}
                    />
                  </Autocomplete>
                </div>
              </div>
            )}
            {formik?.values?.complete_pickup_address && (
              <div className="flex gap-4">
                <div className="w-[95%]">
                  <CustomSelect
                    icon={address_icn}
                    value={formik?.values?.complete_pickup_address}
                    placeholder="Job Address"
                    isInput
                    isDisable
                    formik={formik}
                    label={
                      accuracy
                        ? `Accurate to ${accuracy?.toFixed(2)} meters`
                        : ""
                    }
                    errors={errors}
                  />
                </div>
                <div
                  className="flex justify-center items-center min-w-[40px]"
                  style={{
                    backgroundColor: "#5b6eb6",
                    maxHeight: 40,
                    borderRadius: 5,
                  }}
                >
                  <FaLocationArrow
                    size={25}
                    className=""
                    onClick={getLocation}
                    cursor="pointer"
                    color="white"
                  />
                </div>
                <div
                  className="flex justify-center items-center min-w-[40px]"
                  style={{
                    backgroundColor: "#5b6eb6",
                    maxHeight: 40,
                    borderRadius: 5,
                  }}
                >
                  <BiRefresh
                    size={25}
                    className=""
                    onClick={() => {
                      formik.setFieldValue("complete_pickup_address", "");
                      formik.setFieldValue("pickup_zip", "");
                      formik.setFieldValue("pickup_state", "");
                      formik.setFieldValue("pickup_city", "");
                    }}
                    cursor="pointer"
                    color="white"
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <div className="grid lg:grid-cols-3 gap-2">
          <CustomSelect
            // icon={id}
            // label="Please enter the Zip."
            placeholder="Zip"
            isInput={true}
            formik={formik}
            name="pickup_zip"
            value={formik?.values?.pickup_zip}
            errors={errors}
          />
          <CustomSelect
            // icon={id}
            // label="Please enter the State."
            placeholder="State"
            isInput={true}
            formik={formik}
            name="pickup_state"
            value={formik?.values?.pickup_state}
            errors={errors}
          />
          <CustomSelect
            // icon={id}
            // label="Please enter the City."
            placeholder="City"
            isInput={true}
            formik={formik}
            name="pickup_city"
            value={formik?.values?.pickup_city}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleFormLocation;
