import React, { useEffect, useState } from "react";
import { FaCopy, FaEdit, FaTrashAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { get_inbound_group } from "../../../features/inboundGroupSlice";
import PageHeader from "../../../components/molecules/PageHeader";
import AddDidsForm from "./AddDIDsForm";
import {
  delete_dids,
  get_dids,
  didReset,
  get_cc_cards,
  get_cc_providers,
} from "../../../features/didSlice";
import userPermission from "../../../util/userPermission";
import errorMessage from "../../../util/errorMessage";
import MUIDataTable from "../../../components/molecules/DataTable/muigrid";
import api from "../../../services/api";
import { DotsLoader } from "../../../components";
import CopyDidsForm from "./CopyDIDsForm";
import ImportDIDsForm from "./ImportDIDsForm";
import { get_industry_drd } from "../../../features/IndustrySlice";
import { utils, writeFile } from "xlsx";
import RouteDetailView from "./RouteDetailView";
import { get_merchants } from "../../../features/merchantSlice";
import { get_dialer_users } from "../../../features/usersSlice";
const DIDsGroups = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.dids);
  const { industryDrd } = useSelector((state) => state.industry);
  const merchants = useSelector(
    (state) => state.merchant?.record?.data || []
  );
  const { dialerUsers } = useSelector((state) => state.users);
  const inbound = useSelector((state) => state.inbound);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCopyForm, setIsCopyForm] = useState(false);
  const [isImportForm, setIsImportForm] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [callManu, setCallMenu] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsCopyForm(false);
    setIsImportForm(false);
    setIsViewDetail(false);
  };
  const initalData = {
    did_route: null,
    did_active: null,
    group_id: null,
    extension: null,
    menu_id: null,
    did_carrier_description: null,
    user: null,
    voicemail_ext: null,
    route_external: null,
    custom_one: null,
  };
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [selectedData, setSelectedData] = useState([]);
  const [selectedPayload, setSelectedPayload] = useState(initalData);
  const [sqlQuerry, setSqlQuerry] = useState("");
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedPayload({ ...selectedPayload, [name]: value });
  };

  const onCancel = () => {
    setSelectedPayload(initalData);
    setSelectedData([]);
    setIsSelectAll(false);
  };
  const didRoutesOption = [
    { value: "EXTEN", label: "External Number" },
    { value: "IN_GROUP", label: "Ring Group" },
    { value: "CALLMENU", label: "Call Menu" },
    { value: "AGENT", label: "Agent" },
  ];
  const didStatusOption = [
    { value: "Y", label: "Active" },
    { value: "N", label: "Not Active" },
  ];
  const bulkOptions = [
    {
      placeholder: "Select Status",
      name: "did_active",
      label: "Active",
      options: didStatusOption,
      valueProp: "value",
      labelProp: "label",
      isSelect: true,
      onChange: handleChange,
      value: selectedPayload?.did_active,
    },
    {
      placeholder: "Select DID Route",
      name: "did_route",
      label: "DID Route",
      options: didRoutesOption,
      valueProp: "value",
      labelProp: "label",
      isSelect: true,
      onChange: handleChange,
      value: selectedPayload?.did_route,
    },
    {
      placeholder: "Select In-Group",
      name: "group_id",
      label: "Ring Group",
      options: inbound?.record,
      valueProp: "group_id",
      labelProp: "group_name",
      isSelect: true,
      onChange: handleChange,
      isHidden: selectedPayload?.did_route !== "IN_GROUP",
      value: selectedPayload?.group_id,
    },
    {
      name: "extension",
      label: "External No.",
      isInput: true,
      onChange: handleChange,
      isHidden: selectedPayload?.did_route !== "EXTEN",
      value: selectedPayload?.extension,
    },
    {
      name: "custom_one",
      label: "Company Name",
      isInput: true,
      onChange: handleChange,
      isHidden: selectedPayload?.did_route !== "EXTEN",
      value: selectedPayload?.custom_one,
    },
    {
      placeholder: "Select Call Menu",
      name: "menu_id",
      label: "Call Menu",
      options: callManu,
      valueProp: "menu_id",
      labelProp: "menu_name",
      isSelect: true,
      onChange: handleChange,
      isHidden: selectedPayload?.did_route !== "CALLMENU",
      value: selectedPayload?.menu_id,
    },
    {
      placeholder: "Select External Route",
      name: "route_external",
      label: "External Route",
      options: [
        { value: "YES", label: "YES" },
        { value: "NO", label: "NO" },
      ],
      valueProp: "value",
      labelProp: "label",
      isSelect: true,
      onChange: handleChange,
      isHidden: selectedPayload?.did_route !== "CALLMENU",
      value: selectedPayload?.route_external,
    },
    {
      placeholder: "Select Agent",
      name: "user",
      label: "Agent",
      options: dialerUsers,
      valueProp: "value",
      labelProp: "label",
      isSelect: true,
      onChange: (e) => {
        setSelectedPayload({
          ...selectedPayload,
          user: e.target.value,
          voicemail_ext: dialerUsers?.find(
            ({ value }) => value === e.target.value
          )?.dialer_phone,
        });
      },
      isHidden: selectedPayload?.did_route !== "AGENT",
      value: selectedPayload?.user,
    },
    {
      placeholder: "Select Industry",
      name: "did_carrier_description",
      label: "Industry",
      options: industryDrd,
      valueProp: "_id",
      labelProp: "name",
      isSelect: true,
      onChange: handleChange,
      value: selectedPayload?.did_carrier_description,
    },
  ];

  const handleBulkSave = async () => {
    const allValuesNull = Object.values(selectedPayload).every(
      (value) => value === null || value === ""
    );
    if (allValuesNull) {
      return toast.error("No any value selected");
    } else if (
      selectedPayload?.did_route &&
      !(
        selectedPayload?.extension ||
        selectedPayload?.group_id ||
        selectedPayload?.menu_id ||
        selectedPayload?.user
      )
    ) {
      return toast.error(
        `Selected DID Route is "${selectedPayload?.did_route}" but its value is empty`
      );
    }
    setIsLoader(true);
    const modifiedData = [];
    isSelectAll
      ? (() => {
          let payload = {
            did_id: -1,
          };
          if (
            selectedPayload?.did_route &&
            (selectedPayload?.extension ||
              selectedPayload?.group_id ||
              selectedPayload?.menu_id)
          ) {
            switch (selectedPayload?.did_route) {
              case "EXTEN":
                payload.extension = selectedPayload?.extension
                  ? user?.conexcs_settings?.dial_out_prefix +
                    selectedPayload?.extension
                  : "";
                payload.custom_one = selectedPayload?.custom_one;
                break;
              case "IN_GROUP":
                payload.group_id = selectedPayload?.group_id;
                break;
              case "CALLMENU":
                payload.menu_id = selectedPayload?.menu_id;
                payload.route_external = selectedPayload?.route_external;
                break;
              case "AGENT":
                payload.user = selectedPayload?.user;
                payload.voicemail_ext = selectedPayload?.voicemail_ext;
                break;
              default:
                break;
            }
          }
          if (selectedPayload?.did_active) {
            payload.did_active = selectedPayload?.did_active;
          }
          if (selectedPayload?.did_carrier_description) {
            payload.did_carrier_description =
              selectedPayload?.did_carrier_description;
          }
          if (
            selectedPayload?.did_route &&
            (selectedPayload?.extension ||
              selectedPayload?.group_id ||
              selectedPayload?.menu_id)
          ) {
            payload.did_route = selectedPayload?.did_route;
          }
          modifiedData.push(payload);
        })()
      : selectedData.forEach((record) => {
          let payload = {
            did_id: record?.did_id,
          };
          switch (selectedPayload?.did_route) {
            case "EXTEN":
              payload.extension = selectedPayload?.extension
                ? user?.conexcs_settings?.dial_out_prefix +
                  selectedPayload?.extension
                : "";
              payload.custom_one = selectedPayload?.custom_one;
              break;
            case "IN_GROUP":
              payload.group_id = selectedPayload?.group_id;
              break;
            case "CALLMENU":
              payload.menu_id = selectedPayload?.menu_id;
              payload.route_external = selectedPayload?.route_external;
              break;
            case "AGENT":
              payload.user = selectedPayload?.user;
              payload.voicemail_ext = selectedPayload?.voicemail_ext;
              break;
            default:
              break;
          }
          if (selectedPayload?.did_active) {
            payload.did_active = selectedPayload?.did_active;
          }
          if (selectedPayload?.did_route) {
            payload.did_route = selectedPayload?.did_route;
          }
          if (selectedPayload?.did_carrier_description) {
            payload.did_carrier_description =
              selectedPayload?.did_carrier_description;
          }
          modifiedData.push(payload);
        });
    try {
      const res = await api.post("/api/cc/dids/bulk_update", {
        sqlwhere: sqlQuerry,
        did_data: modifiedData,
        did_cx: {
          cx: user?.conexcs_settings?.cx || "",
          destination_ip: user?.conexcs_settings?.destination_ip || "",
          dial_out_prefix: user?.conexcs_settings?.dial_out_prefix || "",
        },
      });
      if (res?.data?.status) {
        setSelectedData([]);
        setIsSelectAll(false);
        setSelectedPayload(initalData);
        toast.success(res?.data?.message);
        dispatch(
          get_dids({
            page: paginationModel?.page,
            size: paginationModel?.pageSize,
            sqlwhere: sqlQuerry,
          })
        );
      } else {
        toast.error("Data coludn't be updated");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Data coludn't be updated");
      setIsLoader(false);
      console.log("🚀 ~ handleBulkSave ~ err:", err);
    }
  };

  const handleBulkDelete = async () => {
    const c = window.confirm(
      `Are you sure want to delete all selected records? This change will not be revert.`
    );
    if (!c) return;
    setIsLoader(true);
    const modifiedData = [];
    isSelectAll
      ? (() => {
          let payload = {
            did_id: -1,
          };
          modifiedData.push(payload);
        })()
      : selectedData.forEach((record) => {
          let payload = {
            did_id: record?.did_id,
          };
          modifiedData.push(payload);
        });
    try {
      const res = await api.post("/api/cc/dids/bulk_delete", {
        sqlwhere: sqlQuerry,
        did_data: modifiedData,
      });
      if (res?.data?.status) {
        setSelectedData([]);
        setIsSelectAll(false);
        setSelectedPayload(initalData);
        toast.success(res?.data?.message);
        dispatch(
          get_dids({
            page: paginationModel?.page,
            size: paginationModel?.pageSize,
            sqlwhere: sqlQuerry,
          })
        );
      } else {
        toast.error("Data coludn't be deleted");
      }
      setIsLoader(false);
    } catch (err) {
      toast.error("Data coludn't be deleted");
      setIsLoader(false);
      console.log("🚀 ~ handleBulkSave ~ err:", err);
    }
  };
  const deleteRecordHandler = (record_id) => async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_dids(record_id));
        if (res.payload?.status === 200) {
          toast.success("DID deleted Successfully");
          setNewRecord(!newRecord);
        } else {
          errorMessage({ payload: res.payload, action: "DID", msg: "deleted" });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "DID", msg: "deleted" });
      }
    }
  };

  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const openViewDetailHandler = (record) => {
    setEditingRecord(record);
    setIsViewDetail(true);
  };
  const openCopyFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsCopyForm(true);
  };
  const openImportFormHandler = () => {
    setIsImportForm(true);
  };
  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ constget_call_menus=async ~ err:", err);
    }
  };
  useEffect(() => {
    dispatch(
      get_dids({
        page: paginationModel?.page,
        size: paginationModel?.pageSize,
        sqlwhere: sqlQuerry,
      })
    );
    dispatch(get_inbound_group());
    dispatch(get_industry_drd());
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    dispatch(get_merchants());
    dispatch(get_dialer_users());
    get_call_menus();
    return () => {
      dispatch(didReset());
    };
    // eslint-disable-next-line
  }, [newRecord]);
  const didFilter = record?.data?.filter(
    (did) =>
      did?.extension?.toLowerCase().includes(searchText.toLowerCase()) ||
      did?.did_description?.toLowerCase().includes(searchText.toLowerCase()) ||
      did?.user_group?.toLowerCase().includes(searchText.toLowerCase()) ||
      did?.did_route?.toLowerCase().includes(searchText.toLowerCase())
  );
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (did) => did?.did_id !== data?.did_id
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = didFilter?.map((item) => {
        const { did_id, did_route, did_active, group_id, extension, menu_id } =
          item;
        return {
          did_id,
          did_route,
          did_active,
          group_id,
          extension,
          menu_id,
        };
      });
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (did) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ did_id }) => did_id === did.did_id).length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === didFilter?.length || isSelectAll}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records) || isSelectAll}
          onChange={(e) => {
            const {
              did_id,
              did_route,
              did_active,
              group_id,
              extension,
              menu_id,
            } = params.row.records;
            onSingleSelect({
              checked: e.target.checked,
              data: {
                did_id,
                did_route,
                did_active,
                group_id,
                extension,
                menu_id,
              },
            });
          }}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 60, filterable: false },
    { headerName: "DID Number", field: "did_pattern", flex: 1, minWidth: 120 },
    { headerName: "Call Count", field: "total_count", flex: 1, minWidth: 100 },
    {
      headerName: "Merchant",
      field: "user_group",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      filterable: false,
      getOptionValue: (value) => value?.user_group_id?.user_group,
      getOptionLabel: (value) => value?.company_name,
      valueOptions: merchants?.filter((item) => !!item?.user_group_id),
      renderCell: (params) => params.value?.user_group,
      valueGetter: (params) => params.row?.user_group,
      valueFormatter: (params) => params?.value,
    },
    {
      headerName: "Site Name",
      field: "did_description",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "did_carrier_description",
      headerName: "Industry",
      type: "singleSelect",
      flex: 1,
      minWidth: 120,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      renderCell: (params) => params?.value?.did_carrier_description,
      valueGetter: (params) => params.row.did_carrier_description,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "DID Route",
      field: "did_route",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: didRoutesOption,
      renderCell: (params) => params.value.did_route,
      valueGetter: (params) => params.row.did_route,
      valueFormatter: (params) => params.value,
      minWidth: 100,
    },
    {
      headerName: "Ring Group",
      field: "group_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      getOptionValue: (value) => value?.group_id,
      getOptionLabel: (value) => value.group_name,
      valueOptions: inbound?.record,
      renderCell: (params) =>
        params.row.inbound_group_name ? (
          <span
            className="hover:underline cursor-pointer"
            onClick={() => {
              const ingroup = inbound?.record?.find(
                ({ group_id }) => group_id === params.row.group_id
              );
              if (!ingroup) return toast.error("No record found");
              openViewDetailHandler({ ...ingroup, type: "Inbound Group" });
            }}
          >
            {params.row.inbound_group_name}
          </span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
      valueGetter: (params) => params.row.group_id || "",
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "External Number",
      field: "extension",
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params.row.extension ? (
          <span>{params.row.extension}</span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
    },

    {
      headerName: "Call Menu",
      field: "menu_id",
      flex: 1,
      type: "singleSelect",
      minWidth: 120,
      getOptionValue: (value) => value?.menu_id,
      getOptionLabel: (value) => value.menu_name,
      valueOptions: callManu,
      renderCell: (params) =>
        params.row.menu_name ? (
          <span
            className="hover:underline cursor-pointer"
            onClick={() => {
              const recordFound = callManu?.find(
                ({ menu_id }) => menu_id === params.row.menu_id
              );
              if (!recordFound) return toast.error("No record found");
              openViewDetailHandler({ ...recordFound, type: "Call Menu" });
            }}
          >
            {params.row.menu_name}
          </span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
      valueGetter: (params) => params.row.menu_id || "",
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Agent",
      field: "user",
      flex: 1,
      minWidth: 120,
      renderCell: (params) =>
        params.row.user ? (
          <span>{params.row.user}</span>
        ) : (
          <span className="w-full text-red-600 text-center">x</span>
        ),
    },
    {
      headerName: "Status",
      field: "did_active",
      flex: 1,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: didStatusOption,
      renderCell: (params) => params.row.status || "",
      valueGetter: (params) => params.row.did_active || "",
      valueFormatter: (params) => params.value,
      minWidth: 100,
    },
    {
      field: "actions",
      filterable: false,
      minWidth: 100,
      renderCell: (params) =>
        params.row.records.did_active ? (
          <div>
            {userPermission("Update Did") && (
              <FaEdit
                onClick={openFormHandler(params.row.records)}
                className="my_navIcon"
                title="Update DID"
              />
            )}
            {userPermission("Remove Did") && (
              <FaTrashAlt
                onClick={deleteRecordHandler(params.row.records.did_id)}
                className="my_navIcon"
                title="Delete DID"
              />
            )}
            <FaCopy
              onClick={openCopyFormHandler(params.row.records)}
              className="my_navIcon"
              title="Make a copy of this DID"
            />
          </div>
        ) : null,
    },
  ];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_dids({
        sqlwhere: sqlQuerry,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_dids({
          sqlwhere: "",
          page: 1,
          size: paginationModel.pageSize,
        })
      );
      return setSqlQuerry("");
    }
    let searchCondition = [];
    if (filterModel.items.length > 0) {
      filterModel?.items?.forEach((rule) => {
        switch (rule.operator) {
          case "contains":
            searchCondition.push(`${rule.field} LIKE '%${rule.value}%'`);
            break;
          case "startsWith":
            searchCondition.push(`${rule.field} LIKE '${rule.value}%'`);
            break;

          case "endsWith":
            searchCondition.push(`${rule.field} LIKE '%${rule.value}'`);
            break;
          case "equals":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "is":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "=":
            searchCondition.push(`${rule.field} = '${rule.value}'`);
            break;
          case "!=":
            searchCondition.push(`${rule.field} != '${rule.value}'`);
            break;
          case "not":
            searchCondition.push(`${rule.field} != '${rule.value}'`);
            break;
          case ">=":
            searchCondition.push(`${rule.field} >= '${rule.value}'`);
            break;
          case ">":
            searchCondition.push(`${rule.field} > '${rule.value}'`);
            break;
          case "<=":
            searchCondition.push(`${rule.field} <= '${rule.value}'`);
            break;
          case "<":
            searchCondition.push(`${rule.field} < '${rule.value}'`);
            break;
          case "isAnyOf":
            searchCondition.push(
              `${rule.field} IN (${rule?.value
                ?.map((d) => `"${d}"`)
                .join(",")})`
            );
            break;
          case "isEmpty":
            searchCondition.push(`${rule.field} IS NULL OR ${rule.field} = ""`);
            break;
          case "isNotEmpty":
            searchCondition.push(`${rule.field} IS NOT NULL`);
            break;
          case "yes":
            searchCondition.push(`${rule.field} = Y`);
            break;
          case "no":
            searchCondition.push(`${rule.field} = N`);
            break;
          default:
        }
      });
    }
    const searchStr =
      searchCondition.length > 0
        ? searchCondition.join(` ${filterModel?.logicOperator.toUpperCase()} `)
        : ``;
    setSqlQuerry(searchStr);
    // eslint-disable-next-line
  }, []);
  const applyFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_dids({
        sqlwhere: sqlQuerry,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
  };
  const handleExport = async () => {
    setIsLoader(true);
    try {
      const res = await api.post(`/api/cc/dids/get_dids`, {
        sqlwhere: sqlQuerry,
        page: 1,
        size: 1,
      });
      if (res?.status === 200) {
        const allDids = await api.post(`/api/cc/dids/get_dids`, {
          sqlwhere: sqlQuerry,
          page: 1,
          size: res?.data?.totalItems,
        });
        const data = allDids?.data?.data;
        const rows = [
          [
            "#",
            "DID Number",
            "Call Count",
            "Site Name",
            "Industry",
            "DID Route",
            "In-Group",
            "Extension",
            "Call Menu",
            "Status",
          ],
        ];
        data.forEach((item, index) => {
          const {
            extension,
            menu_name,
            inbound_group_name,
            did_route,
            did_description,
            did_pattern,
            did_active,
            did_carrier_description,
          } = item;
          const counter = index + 1;
          const status = did_active === "Y" ? "Active" : "InActive";
          const industry = industryDrd?.find(
            ({ _id }) => _id === did_carrier_description
          )?.name;
          rows.push([
            counter,
            did_pattern,
            did_description,
            industry,
            did_route,
            inbound_group_name,
            extension,
            menu_name,
            status,
          ]); // Add a row without domains
        });
        const workbook = utils.book_new();
        const sheetName = "DIDs list";
        const worksheet = utils.aoa_to_sheet(rows);
        utils.book_append_sheet(workbook, worksheet, sheetName);

        writeFile(workbook, "did_list.xlsx");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      console.log("🚀 ~ handleExport ~ err:", err);
    }
  };
  // const heading = columnDefs?.map(({ headerName, field }) => ({
  //   heading: headerName,
  //   value: field,
  // }));
  return (
    <>
      {isEditing && (
        <AddDidsForm
          editingRecord={editingRecord}
          modalTitle="Add DIDs"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
          callManu={callManu}
        />
      )}
      {isViewDetail && (
        <RouteDetailView
          data={editingRecord}
          modalTitle={`${editingRecord?.type} Detail`}
          onCancelForm={cancelFormHandler}
          callManu={callManu}
        />
      )}
      {isCopyForm && (
        <CopyDidsForm
          editingRecord={editingRecord}
          modalTitle="Copy DIDs"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
          callManu={callManu}
          paginationModel={paginationModel}
          sqlQuerry={sqlQuerry}
        />
      )}

      {isImportForm && (
        <ImportDIDsForm
          editingRecord={editingRecord}
          modalTitle="Import DIDs"
          onCancelForm={cancelFormHandler}
          onSave={setNewRecord}
          newRecord={newRecord}
          callManu={callManu}
          paginationModel={paginationModel}
          sqlQuerry={sqlQuerry}
        />
      )}

      {isLoader || isLoading ? <DotsLoader /> : null}

      <PageHeader
        route="Call Center > DIDs"
        heading="DIDs Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Add Did")}
        additionalBtnTitle="Import DIDs"
        extraButton
        additionalBtnAllow={user?.role?.toLowerCase()?.includes("super administrator")}
        extraButtonClick={openImportFormHandler}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={didFilter?.map((record, index) => {
            let counter = index + 1;
            let status = record.did_active === "Y" ? "Active" : "InActive";
            const {
              extension,
              menu_name,
              did_id,
              inbound_group_name,
              did_route,
              did_description,
              did_pattern,
              group_id,
              menu_id,
              did_active,
              total_count,
              did_carrier_description,
              user_group,
              user,
            } = record;
            return {
              counter,
              records: { ...record, _id: did_id },
              did_pattern,
              did_description,
              did_route:
                did_route === "IN_GROUP"
                  ? "Ring Group"
                  : did_route === "EXTEN"
                  ? "External Number"
                  : did_route === "CALLMENU"
                  ? "Call Menu"
                  : did_route,
              inbound_group_name:
                did_route === "IN_GROUP" ? inbound_group_name : "",
              group_id,
              status,
              total_count,
              extension:
                did_route === "EXTEN" && extension
                  ? extension?.replace(
                      user?.conexcs_settings?.dial_out_prefix,
                      ""
                    )
                  : "",
              user_group,
              menu_name: did_route === "CALLMENU" ? menu_name : "",
              menu_id,
              did_active,
              // did_carrier_description
              did_carrier_description: industryDrd?.find(
                ({ _id }) => _id === did_carrier_description
              )?.name,
              user: dialerUsers?.find(({ value }) => value === user)?.label,
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          totalItems={record?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          paginationMode="server"
          customSize={2000}
          toolbarProps={{
            isHideColumnButton: true,
            isHideDensityButton: true,
            isBulkUpdate: true,
            fieldsArray: bulkOptions,
            isSelectAll: isSelectAll,
            setIsSelectAll: setIsSelectAll,
            setSelectedData: setSelectedData,
            showSelectAll: true,
            selectedItem: isSelectAll
              ? record?.totalItems
              : selectedData?.length,
            totalItems: record?.totalItems,
            selectedPayload: selectedPayload,
            handleBulkSave: handleBulkSave,
            handleBulkDelete: handleBulkDelete,
            onCancel: onCancel,
            applyFilter: applyFilter,
          }}
          exportButton={handleExport}
        />
      </div>
    </>
  );
};

export default DIDsGroups;
