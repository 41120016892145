import React, { useEffect, useState } from "react";
import RoleForm from "./AddUpdateForm";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/molecules/PageHeader";
import { FaEdit, FaTrash } from "react-icons/fa";
import userPermission from "../../util/userPermission";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import {
  delete_monitor,
  get_monitors,
  update_status,
} from "../../features/uptimeRobotSlice ";
import { FaPlay, FaPause } from "react-icons/fa6";
const UptimeMonitor = () => {
  const dispatch = useDispatch();
  const { isLoading, record } = useSelector((state) => state.uptime);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  // pending for future implementation
  const openFormHandler = (record) => () => {
    setEditingRecord(record);
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(get_monitors());
    // eslint-disable-next-line
  }, []);
  const monitors = record?.monitors
    ?.filter((record) => {
      const matchesStatus =
        selectedStatus === null || record?.status === selectedStatus;
      const matchesSearch = Object.keys(record).some(
        (key) =>
          record[key] &&
          record[key]
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );

      return matchesStatus && matchesSearch;
    })
    .sort((a, b) => {
      if (a.status === 9 && b.status !== 9) return -1;
      if (a.status !== 9 && b.status === 9) return 1;
      return 0;
    });

  const deleteMonitor = async (id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) {
      return;
    }
    try {
      const res = await dispatch(delete_monitor(id));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        dispatch(get_monitors());
      }
    } catch (err) {
      console.error(err);
    }
  };
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Name", field: "friendly_name", flex: 1 },
    {
      headerName: "URL",
      field: "url",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${params?.row?.url}`}
              className="no-underline hover:underline cursor-pointer"
            >
              {params?.row?.url}
            </a>{" "}
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        return (
          <span
            className={`${
              status === "Paused"
                ? "text-[#f3a34f]"
                : status === "Down"
                ? "text-red-600"
                : status === "Up"
                ? "text-green-600"
                : ""
            } font-bold`}
          >
            {status}
          </span>
        );
      },
    },
    { headerName: "Last 7 Days", field: "weekly", flex: 1 },
    { headerName: "Last 30 Days", field: "monthly", flex: 1 },
    { headerName: "Last 365 Days", field: "yearly", flex: 1 },
    {
      field: "actions",
      renderCell: (params) => (
        <div>
          {params.row.status === "Paused" ? (
            <FaPlay
              onClick={() => handleStatus(params.row.records.id, 1)}
              className="my_navIcon"
              title="Resume"
            />
          ) : (
            <FaPause
              onClick={() => handleStatus(params.row.records.id, 0)}
              className="my_navIcon"
              title="Pause"
            />
          )}
          <FaEdit
            onClick={openFormHandler(params.row.records)}
            className="my_navIcon"
            title="Update Monitor"
          />
          <FaTrash
            onClick={() => deleteMonitor(params.row.records.id)}
            className="my_navIcon  mr-3"
            title="Delete Monitor"
          />
        </div>
      ),
    },
  ];
  const handleStatus = async (recordId, status) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (!c) {
      return;
    }
    try {
      const payload = {
        id: recordId,
        status: status,
      };
      const res = await dispatch(update_status(payload));
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        toast.success("Uptime monitor status changed.");
        dispatch(get_monitors());
      }
    } catch (err) {
      console.error(err);
    }
  };
  const CustomComponent = () => {
    return (
      <div className="flex gap-2 ml-1 mb-2 flex-wrap">
        <button
          onClick={() => setSelectedStatus(null)}
          className={`inline-flex items-center justify-center rounded text-sm font-semibold p-2 whitespace-nowrap w-full md:max-w-[12%] ${
            selectedStatus === null ? "bg-primary-100" : "bg-gray-200"
          } border cursor-pointer`}
        >
          {"All Monitors"}
        </button>
        {uniqueStatuses?.map((status) => (
          <button
            key={status}
            onClick={() => setSelectedStatus(status)}
            className={`inline-flex items-center justify-center rounded text-sm font-semibold p-2 whitespace-nowrap w-full md:max-w-[15%] ${
              selectedStatus === status ? "bg-primary-100" : "bg-gray-200"
            } border cursor-pointer`}
          >
            {statusLabels[status] || "Unknown"} ({statusCounts[status]} domains)
          </button>
        ))}
      </div>
    );
  };

  const getStatusCounts = (data) => {
    if (!data) return {};
    return data.reduce((acc, item) => {
      const status = item.status;
      if (acc[status]) {
        acc[status]++;
      } else {
        acc[status] = 1;
      }
      return acc;
    }, {});
  };

  const statusLabels = {
    0: "Paused",
    1: "Not Checked Yet",
    2: "Up",
    8: "Seems Down",
    9: "Down",
  };

  const statusCounts = getStatusCounts(record?.monitors);
  const uniqueStatuses = Object.keys(statusCounts).map(Number);
  return (
    <>
      {isEditing && (
        <RoleForm
          editingRecord={editingRecord}
          modalTitle="Add Monitor"
          onCancelForm={cancelFormHandler}
        />
      )}
      <PageHeader
        route="Setting > User Roles"
        heading="Uptime Monitor Listing"
        onClick={openFormHandler(0)}
        isAllowed={userPermission("Administration")}
      />

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={monitors?.map((record, index) => {
            let counter = index + 1;
            const { custom_uptime_ratio, friendly_name, status, url } = record;
            return {
              counter,
              records: { ...record },
              weekly: custom_uptime_ratio?.split("-")[0],
              monthly: custom_uptime_ratio?.split("-")[1],
              yearly: custom_uptime_ratio?.split("-")[2],
              friendly_name,
              status:
                status === 0
                  ? "Paused"
                  : status === 1
                  ? "Not Checked Yet"
                  : status === 2
                  ? "Up"
                  : status === 8
                  ? "Seems Down"
                  : status === 9
                  ? "Down"
                  : "Unknown",
              url,
            };
          })}
          pagination="No"
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          isLoading={isLoading}
          CustomComponent={CustomComponent}
          searchable="No"
        />
      </div>
    </>
  );
};

export default UptimeMonitor;
